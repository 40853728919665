@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  img {
    @apply inline-block;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

body {
  font-family: sans-serif;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
  height: 10px; /* Height of the horizontal scrollbar */
}

/* Track (background of the scrollbar) */
::-webkit-scrollbar-track {
  background: transparent; /* Light background */
  border-radius: 4px; /* Rounded corners */
}

/* Handle (the draggable scrolling element) */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.24); /* Darker color for contrast */
  border-radius: 4px; /* Rounded corners */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5); /* Slightly darker on hover for feedback */
}

.space-grotesk-bold {
  font-family: "Space Grotesk", sans-serif;
  font-weight: 700;
}

.space-grotesk-light {
  font-family: "Space Grotesk", sans-serif;
  font-weight: 300;
}

.space-grotesk-medium {
  font-family: "Space Grotesk", sans-serif;
  font-weight: 500;
}
